<template>
    <div class="piclist">
        <!-- 下方的筛选项目连带搜索 -->
        <div class="search-box" v-if="list && listshow">
            <div class="search_inp">
                <el-input placeholder="请输入素材名称" prefix-icon="el-icon-search" size="small" v-model="name" clearable @input="inputBtn" @clear="reset" @keyup.enter.native="reset">
                </el-input>
            </div>
        </div>
        <!-- 列表标题 -->
        <div class="list-title" v-if="list && list.length">
            <div class="title">背景</div>
            <div class="icon">
                <span v-if="!listshow" @click="listshowchange(true)">更多<i class="el-icon-arrow-right"></i></span>
                <i class="el-icon-arrow-left" v-else @click="listshowchange(false)"></i>
            </div>
        </div>
        <!-- 分类 -->
        <div class="cate-list" v-if="list && list.length && listshow">
            <div class="item" :class="{ 'active': bg_type == item.value }" v-for="(item, i) in typelist" :key="i" @click="cateChange(item)">{{ item.label }}</div>
        </div>
        <!-- 列表 -->
        <div class="contentbox" v-if="!listshow">
            <el-checkbox-group ref="selectids" v-model="checkList">
                <div class="item openHover" v-for="(item, x) in list.slice(0, 3)" :key="x">
                    <div class="item-pic">
                        <div class="picbox">
                            <el-image style="width: 96px; height: 70px" :src='item.thumbnail' fit="contain">
                                <div slot="error" class="image-slot">
                                    <img src="@/assets/images/icon/img.png" alt="">
                                </div>
                            </el-image>
                        </div>
                        <!-- 左上角的眼睛 -->
                        <div class="preview" @click="look(item)">
                            <i class="el-icon-view color"></i>
                        </div>
                        <!-- 下方的插入按钮 -->
                        <div class="insert">
                            <div class="button" @click="selectMaterials(item)">插入</div>
                        </div>
                    </div>
                </div>
            </el-checkbox-group>
        </div>
        <div class="contentbox scroll_bar" v-else infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.1)">
            <el-checkbox-group v-model="checkList">
                <div class="item openHover" v-for="(item, x) in list" :key="x" :class="{ 'selectHover': listshow }" @change="checkChange(item)" @mouseenter="mouseenter(item)" @mouseleave="mouseleave(item)">
                    <div class="item-pic">
                        <div class="picbox">
                            <el-image style="width: 96px; height: 70px" :src='item.thumbnail' fit="contain">
                                <div slot="error" class="image-slot">
                                    <img src="@/assets/images/icon/img.png" alt="">
                                </div>
                            </el-image>
                        </div>
                        <!-- 左上角的眼睛 -->
                        <div class="preview" @click="look(item)">
                            <i class="el-icon-view color"></i>
                        </div>
                        <!-- 下方的插入按钮 -->
                        <div class="insert">
                            <div class="button" @click="selectMaterials(item)">插入</div>
                        </div>
                        <!-- 左边的选中框 -->
                        <div class="selectCheck" v-if="item.checked || item.flag">
                            <el-checkbox :label="item.id"><br /></el-checkbox>
                        </div>
                    </div>
                </div>
            </el-checkbox-group>
            <!-- 没有更多 -->
            <Loading :loading="loading" :list="list" :page="page" :totalPage="last_page">
            </Loading>
        </div>
        <!-- 预览图片 -->
        <ImgPreview ref="ImgPreview"></ImgPreview>
        <VideoPreview ref="VideoPreview"></VideoPreview>

    </div>
</template>

<script>
import {
    mapState
} from "vuex"
export default {
    components: {
        ImgPreview: resolve => require(['@/components/img_preview/index'], resolve),
        VideoPreview: resolve => require(['@/components/video_preview/index'], resolve),
        Loading: (resolve) => require(['@/components/loading/index'], resolve)

    },
    props: {
        listshow: {
            type: Boolean,
            default: false
        },
        // list: {
        //   type: Array,
        //   default: () => []
        // },
        showtype: {
            type: String,
            default: 'thumbnail'
        }
    },
    data() {
        return {
            checkList: [],
            name: '',
            isSelect: false, // 收否开启选择模式
            checkedAll: false, // 选择所有
            loading: false,
            page: 0,
            last_page: 1, // 总页数
            limit: 20,
            list: [],
            typelist: [ // 分类
                {
                    label: '全部',
                    value: ''
                },
                {
                    label: '图片',
                    value: '1'
                },
                {
                    label: '视频',
                    value: '2'
                }
            ],
            bg_type: '',
        }
    },
    created() {
        this.load()

    },
    watch: {
        checkList(value) {
            this.$emit('upDataDelete', value)
        },
        list(value) {
            this.$emit('getList', value)
        }
    },
    computed: {
        ...mapState({
            sceneList: state => state.videoData.sceneList,//场景列表
            sceneIndex: state => state.videoData.sceneIndex,//选中场景下标
        }),
        background() {
            return this.sceneList[this.sceneIndex].background || {}
        },
        searchForm() {
            return {
                page: this.page,
                limit: this.limit,
                type: 2,
                name: this.name
            }
        },
    },
    methods: {
        inputBtn() { },
        // 加载更多
        load() {
            if (this.loading || this.page === this.last_page || this.last_page === 0) return
            this.page++
            this.getList()
        },
        // 重置
        reset() {
            this.list = []
            this.page = 1
            this.checkedAll = false
            this.deleteIds = []
            this.getList()
        },
        // 切换分类
        cateChange(item) {
            this.bg_type = item.value
            this.reset()
        },
        // 列表
        getList() {
            this.loading = true
            this.$workbenApi.self_material({
                ...this.searchForm,
                bg_type: this.bg_type,
            }).then(res => {
                this.list = [...this.list, ...res?.result?.data] || []
                this.loading = false
                this.last_page = res?.result?.last_page
                this.page = res?.result?.current_page ?? 1
            })
        },
        // 更多
        listshowchange(e) {
            this.$emit('listshowchange', e)
        },
        // 插入素材
        selectMaterials(info) {
            console.log(info)
            this.background.type = 'image'
            this.background.url = info.thumbnail
        },
        // 预览
        look(item) {
            // console.log(item)
            // 图片
            if (item.bg_type == 1) {
                this.$refs.ImgPreview.openDialog(item.url)
            }
            // 视频
            if (item.bg_type == 2) {
                this.$refs.VideoPreview.openDialogBtn(item.url)
            }
        },
        // 全选
        changeSelect(value) {
            this.checkList = value
            if (value.length) {
                this.list.forEach(item => {
                    this.$set(item, 'checked', true)
                })
            } else {
                this.list.forEach(item => {
                    this.$set(item, 'checked', false)
                })
            }
        },
        // 选择
        checkChange(item) {
            var flag = this.checkList.includes(item.id)
            if (flag) {
                this.$set(item, 'checked', true)
            } else {
                this.$set(item, 'checked', false)
            }
        },
        // 鼠标进入
        mouseenter(item) {
            this.$set(item, 'flag', true)

        },
        // 鼠标离开
        mouseleave(item) {
            this.$set(item, 'flag', false)
        },
    }
}
</script>
<style lang="scss" scoped>
@import "../index.scss";

.cate-list {
    display: flex;

    .item {
        width: 42px;
        height: 24px;
        background: #3c4148;
        border-radius: 2px;
        margin-right: 10px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
    }

    .active {
        background: #252f69;
        border: 1px solid #2e4bf2;
    }
}

.contentbox {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 100px);
    margin-right: -8px;
}
</style>
